
.nav-container {
    @media print {
        display: none;
    }
    background-image: radial-gradient(#52126b, #571d6e);
    box-shadow: .2rem .1rem .3rem #666;
}

.navbar-brand {
    background-color: #fff;
    border-radius: .4rem;
    padding: .3rem;
    box-shadow: inset .05rem .05rem .1rem #666;
}


.navbar-toggler {
    border: 1px solid rgba(255, 255, 255);
    
    .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
}

.navbar{
    --fs-purple: #44217A;
    --fs-yellow: #FDBC35;
    --bs-navbar-nav-link-padding-x: 2rem;
    --bs-navbar-nav-link-padding-y: .5rem;
    --bs-emphasis-color-rgb: #fff;

    .navbar-nav {
        margin-top: 2rem;
        flex-grow: 1;
        flex-direction: column;
        
        .nav-link,
        .btn {
            color: #fff;
            padding: var(--bs-navbar-nav-link-padding-y) var(--bs-navbar-nav-link-padding-x);
            width:100%;
            align-self: stretch;
            font-weight: 700;
        }

        .btn {
            background-color: var(--fs-yellow);
            color: var(--fs-purple);
            border: none;
            box-shadow: .1rem .1rem .1rem #303;
        }

        .fs-login {
            margin-top: 1rem;
            .btn {
                text-align: left;
            }
        }

        @media (min-width: 768px) {
            flex-direction: row;
            margin-top: 0;

            .fs-login {
                align-self: flex-end !important;
                margin-left: auto;
                margin-top: 0;
                .btn {
                    text-align: center;
                }
            }
            
            .nav-link, 
            .btn {
                margin-left: 1rem;
            }
        }
    }
}