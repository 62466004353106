.report-choice{
    @media print {
        display: none;
    }
}

section.report-group{
    padding-bottom: 3rem;
    @media print {
        padding-bottom: 0;
    }

    h2{
        color: #fff;
        background-image: radial-gradient(#52126b, #571d6e);
        box-shadow: .2rem .1rem .3rem #666;
        padding: .1rem;
        @media print {
            display: none;
        }
    }

    .sub-group{
        padding-bottom: 2rem;
        @media print {
            padding-bottom: 0;
        }
        
        h3 {
            font-size: 130%;
            color: #000;
            background: #ccc;
            padding: .1rem;
            @media print {
                display: none;
            }
        }

        .report-summary{
            @media print {
                page-break-after: always;
            }
            
            .report-heading{
                display: flex;
                flex-direction: row;
                margin-top: 1rem;
                margin-bottom: 6rem;
                align-items:flex-start;

                img {
                        width: 7rem;
                        vertical-align: top;
                        display: none;
                }
                @media print {
                    img{
                        display: block;
                    }
                }

                .report-scope {
                    margin-left: auto;
                    width: 18rem;
                    dl{
                        display: grid;
                        grid-template-columns: 45% auto;
                    }
                    dt{
                        grid-column: 1;
                    }
                    dd{
                        grid-column: 2;
                        margin: 0;
                    }
                }
            }
            table{
                width: 100%;
                thead{
                    th {
                        white-space: nowrap;
                        padding-right: 1rem;
                    }
                }
                tbody{
                    td {
                        white-space: nowrap;
                        padding-right: 1rem;
                    }
                    td.date {
                            width: 8rem;
                        }
                    td.number, td.date {
                        text-align: right;
                    }
                }
            }
        }
    }
}
